.header{
  position: relative;
  z-index: 20;
  padding: 20px 0;
  border-bottom: 1px solid #353945;

  @media only screen and (max-width: 767px) {
    padding: 48px 0 24px;
  }

  &__center{
    display: flex;
    align-items: center;
  }

  // #######################################
  // Logo
  // #######################################
  &__logo{
    position: relative;
    z-index: 12;
    display: inline-block;
    flex-shrink: 0;
    width: 178px;
    margin-right: 32px;

    @media only screen and (max-width: 1179px) {
      margin-right: auto;
    }

    a{
      display: block;
      position: relative;
    }

    img{
    }

    &_dark{
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;

      opacity: 0;
      visibility: hidden;
    }
    &_light{
      opacity: 1;
      visibility: visible;
    }
  }

  // #######################################
  // Navigation
  // #######################################
  &__nav{
    display: flex;
    margin-right: auto;

    @media only screen and (max-width: 1023px) {
      flex-direction: column;
      margin: 0 0 40px;
      border: none;
    }
  }
  &__link{
    margin-left: 32px;
    font-family: var(--font-2);
    font-weight: 600;
    line-height: 40px;
    letter-spacing: .5px;
    color: #777E90;
    transition: color .2s;
    padding-bottom: 12px;

    &:hover,
    &:focus{
      color: var(--color-blue);
    }

    @media only screen and (max-width: 1023px) {
      margin-left: 0;
      font-size: 24px;
      line-height: 64px;
      color: #777E90;
    }
  }
  &__lists{
    list-style: none outside none;
    display: flex;
    flex-flow: row wrap;
  }
  &__item{
    position: relative;
  }

  // #######################################
  // CTA
  // #######################################
  &__cta{

    a{
      text-transform: uppercase;
      &:not(:last-child){
        margin-right: 12px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0;
        }
      }
      @media only screen and (max-width: 1023px) {
        margin-top: 16px;
        display: inline-flex;
        width: 100%;
      }
    }
  }

  // #######################################
  // Wrapper RWD
  // #######################################
  &__wrapper{
    display: flex;
    align-items: center;
    flex-grow: 1;

    @media only screen and (max-width: 1023px) {
      flex-grow: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      flex-direction: column;
      align-items: stretch;
      min-height: calc(var(--vh, 1vh) * 100);
      margin: 0;
      padding: 140px 32px 40px;
      visibility: hidden;
      opacity: 0;
      transition: all .2s;
    }
  }
}