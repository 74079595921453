// Overwrite Original MaterialUI Dialog Modal
.xid-modal{
  // All MaterialUI Overwrite
  .MuiBackdrop-root{
    background-color: rgba(20, 20, 22, 0.9);
  }
  .MuiDialog-paper{
    margin: 0;
    box-shadow: none;
  }
  .MuiPaper-root{
    background-color: var(--color-neutral-900);
  }

  // Variant Size
  &__medium{
    .MuiDialog-paper{
      max-width: 639px;
      width: 100%;

      @include xid-mq-767{
        max-width: 90%;
      }
    }
  }

  // Inner
  &__inner{
    position: relative;
    // max-width: 448px;
    margin: 0 auto;
    padding: 32px;
    width: 100%;
    // background: #FCFCFD;
    border-radius: 8px;

    @include xid-mq-767{
      padding: 24px;
    }
  }
  .popup{
    &__item{
      display: none;

      @include xid-mq-578{
        padding-top: 58px;
      }
    }

    &__title{
      margin-bottom: 32px;

      @include xid-mq-578{
        text-align: center;
        margin-bottom: 16px;
      }
    }

    &__info{
      margin-bottom: 32px;
      font-size: 16px;
      line-height: 1.5;
      color: var(--color-neutral-500);
      
      strong{
        font-weight: 500;
      }
    }

    .popup__btns {
      margin-top: 40px;
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: center;

      @include xid-mq-578{
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
      }

      .popup__button{
        @include xid-mq-578{
          width: 100%;
        }
      }
    }
    // Close
    .mfp-close{
      position: absolute;
      top: 32px;
      right: 32px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      line-height: normal;
      opacity: 1;
      font-size: 0;
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      border: 2px solid var(--color-white);
      background-color: var(--color-neutral-900);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23FCFCFD' d='M1.613.2l.094.083L5 3.585 8.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 .083 1.32l-.083.094L6.415 5l3.292 3.293a1 1 0 0 1-1.32 1.497l-.094-.083L5 6.415 1.707 9.707A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707a1 1 0 0 1 0-1.414A1 1 0 0 1 1.613.21z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: center;

      @include xid-mq-578{
        left: 50%;
        transform: translateX(-50%);
      }

      &:hover{
        border-color: var(--color-white);
      }
    }
  }



  // Additional
  .stepper{
    &__wrapper{
      margin-bottom: 24px;

      @include xid-mq-578{
        gap: 8px;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .MuiStepConnector-horizontal{
        @include xid-mq-578{
          display: none;
        }
      }
    }
  
    &__item{
      @include xid-mq-578{
        padding-right: 0;
        padding-left: 0;
      }
    }
  }


  &__profile-auth{
    @include xid-mq-578{
      text-align: center;
    }

    .stepper__wrapper{
      @include xid-mq-578{
        justify-content: center;
        align-items: center;
      }

      .MuiStepLabel-iconContainer{
        @include xid-mq-578{
          display: none;
        }
      }
      .MuiStepLabel-label.MuiStepLabel-active{
        @include xid-mq-578{
          color: var(--color-primary);
          font-weight: bold;
        }
      }
      .MuiStepLabel-label.MuiStepLabel-completed {
        @include xid-mq-578{
          color: var(--color-alert-success);
          font-weight: bold;
        }
      }
    }
  }
}