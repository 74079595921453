.xid-table{

  &__wrapper{}

  &__inner{
    display: table;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 6px;
    border: 2px solid rgba(var(--color-neutral-800-rgb), .8);


    @include xid-mq-1023{
      display: block;
    }
  }

  &__row{
    display: table-row;

    @include xid-mq-1023{
      display: flex;
      flex-direction: column;
      padding: 16px 24px;
    }

    &:first-child{
      @include xid-mq-1023{
        display: none;
      }
      .xid-table__col{
        padding-top: 8px;
        padding-bottom: 8px;
        font-weight: 600;
      }
    }
    &:not(:first-child){
      .xid-table__col{
        color: var(--color-white);
      }
    }

    &:nth-child(2n){
      background-color: var(--color-neutral-800);
    }
  }

  &__col{
    display: table-cell;
    padding: 4px 16px;

    @include xid-mq-1023{
      display: flex;
      align-items: center;
      padding: 0;
    }
    @include xid-mq-578{
      display: block;
    }

    &:first-child{
      padding-left: 24px;
      @include xid-mq-1023{
        padding-left: 0;
      }
    }

    &:not(:last-child){
      @include xid-mq-1023{
        margin-bottom: 12px;
      }

      @include xid-mq-578{
        margin-bottom: 24px;
      }
    }

    &:last-child{
      @include xid-mq-1023{
        padding-right: 0;
      }
    }

  }

  &__label{
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    // margin-bottom: 12px;
    color: var(--color-neutral-500);
    display: none;
    @include xid-mq-1023{
      display: block;
      min-width: 120px;
    }
    @include xid-mq-578{
      margin-bottom: 12px;
      min-width: initial;
      width: 100%;
    }
  }
}