$color_styles: (
  'blue': $color-accent-primary,
  'red': $color-accent-red,
  'orange': $color-accent-orange,
);

@each $state, $property in $color_styles {
  .color-#{$state}{
    color: $property !important;
  }
}