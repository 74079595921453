.stepper{
  &__wrapper{
    // This class for overwrite material stepper
    &.MuiStepper-root{
      background: transparent;
      padding: 0;
  
      .stepper__item{
        &:first-child{
          padding-left: 0;
        }
        &:last-child{
          padding-right: 0;
        }
      }
    }

    // Connector
    .MuiStepConnector-root{
      .MuiStepConnector-line{
        border-color: rgba(var(--color-neutral-500-rgb), .3);
      }
    }
  }

  &__item{
    // Icon
    .MuiSvgIcon-root{
      color: var(--color-white);
  
      &.MuiStepIcon-active{
        color: var(--color-primary);
  
        .MuiStepIcon-text{
          fill: var(--color-white);
        }
      }
  
      &.MuiStepIcon-completed{
        color: var(--color-alert-success);
  
        .MuiStepIcon-text{
          fill: var(--color-white);
        }
      }
    }
    .MuiStepIcon-text{
      font-family: var(--font-body);
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      fill: var(--color-black);
    }

    // Text
    .MuiTypography-root{
      font-family: var(--font-body);
      font-size: 14px;
      font-weight: 600;
      color: var(--color-white);
      
      &.MuiStepLabel-completed,
      &.MuiStepLabel-active{
        color: var(--color-white);
      }
    }
  }
}