body{
  min-width: 375px;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  line-height: 1.71429;
  color: var(--color-neutral-500);
  background: var(--color-black);

  #root{
    flex: 1;
  }
}