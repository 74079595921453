// Overwrite from investhub/blocks/checkbox
.xid-field{
  &.field__checkbox{
    margin-left: 0;
    margin-right: 0;
    align-items: flex-start;

    // Checkbox Square Box
    .MuiCheckbox-root{
      border: 2px solid rgba(111, 118, 126, 0.4);

      &:before{
        width: 17px;
        height: 12px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12'%3E%3Cpath d='M16.707.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0L.293 6.707A1 1 0 0 1 .735 5.02a1 1 0 0 1 .973.273l3.586 3.586a1 1 0 0 0 1.414 0L15.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfc' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto;
      }

      &.Mui-checked{
        background: var(--color-primary);
        border-color: var(--color-primary);

        + .MuiFormControlLabel-label{
          color: var(--color-white) !important;
        }
      }
    }

    // Checkbox label
    .MuiFormControlLabel-label{
      color: var(--color-neutral-500) !important;
      font-family: var(--font-body);
      font-weight: 600;

      a{
        color: var(--color-white);

        &:hover,
        &:focus{
          color: var(--color-primary);
        }
      }
    }
  }
}