[class^="section"] {
	padding: 128px 0;

  @include xid-mq-1339{
    padding: 112px 0;
  }

  @include xid-mq-767{
    padding: 64px 0;
  }
}
.section{
  &-pb{
    padding-top: 0;

    @include xid-mq-1179{
      padding-top: 0;
    }

    @include xid-mq-767{
      padding-top: 0;
    }
  }

  &-pt80{
    padding-top: 80px;

    @include xid-mq-1179{
      padding-top: 64px;
    }
    @include xid-mq-767{
      padding-top: 32px;
    }
  }
}