.button-white{
  background-color: white;
  color: #23262F;

  &:hover,
  &:focus{
    background-color: var(--color-blue-dark);
    color: white;
  }
}

.button-dark{
  background-color: #23262F;
  color: white;

  &:hover,
  &:focus{
    background-color: var(--color-blue-dark);
  }
}

.button{
  &-has{
    &--icon{
      svg{
        margin-right: 0 !important;
        margin-left: 4px !important;
        width: 12px;
        height: 12px;
      }
    }
  }
}

// Will remove after merge v2
.site-header{
  [class^="button"],
  .button-stroke{
    font-family: var(--font-1);
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
  }
}
