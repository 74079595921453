.hero, .h1, .h2, .h3, .h4 {
  font-family: var(--font-heading);
  font-weight: 600;
  color: var(--color-white);
}

.hero {
	font-size: 96px;
	line-height: 1;
	letter-spacing: -.02em;
}

.h1 {
  font-size: 64px;
  line-height: 1;
  letter-spacing: -.03em;
}

.h2 {
  font-size: 48px;
  line-height: 1;
  letter-spacing: -.03em;
}

.h3 {
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -.02em;

  @include xid-mq-767{
    font-size: 32px;
    line-height: 1.25;
    letter-spacing: -.03em;
  }
}

.h4 {
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -.03em;
}