.main {
  &__head{
    max-width: 767px;
    margin: 0 auto 128px;
    text-align: center;

    @include xid-mq-1339{
      margin-bottom: 112px;
    }
    @include xid-mq-767{
      margin-bottom: 64px;
    }
  }
  &__title{
    margin-bottom: 16px;

    @include xid-mq-767{
      margin-bottom: 8px;
    }
  }

  &__text{
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 34px;

    @include xid-mq-767{
      margin-bottom: 24px;
    }
  }

  &__wrapper{
    position: relative;
    // margin: 0 -12px;
  }
}