$color_styles: (
  'primary': var(--color-primary),
  'white': var(--color-white),
  'neutral-500': var(--color-neutral-500)
);

@each $state, $property in $color_styles {
  .text-#{$state}{
    color: $property;
  }
  .bg-#{$state}{
    background-color: $property;
  }
}