@mixin xid-mq-1339 {
  @media screen and (max-width: 1339px) {
    @content;
  }
}

@mixin xid-mq-1259 {
  @media screen and (max-width: 1259px) {
    @content;
  }
}

@mixin xid-mq-1179 {
  @media screen and (max-width: 1179px){
    @content;
  }
}

@mixin xid-mq-1023 {
  @media screen and (max-width: 1259px) {
    @content;
  }
}

@mixin xid-mq-767{
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin xid-mq-578{
  @media screen and (max-width: 578px) {
     @content;
  }
}

@mixin xid-mq-389{
  @media screen and (max-width: 389px){
    @content;
  }
}



// 520
// 480