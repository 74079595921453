.hidden{
  display: none !important;
}
.large{
  &-tablet{
    &__show{
      display: none !important;

      @include mq_1179{
        display: block !important;
      }
    }
    &__hide{
      @include mq_1179{
        display: none !important;
      }
    }
  }
}