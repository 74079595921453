@media only screen and (max-width: 1179px) {
	.desktop-hide {
		display: none !important;
	}
}
.desktop-show {
	display: none !important;

  @include xid-mq-1179{
    display: block !important;
  }
}


@media only screen and (max-width: 1023px) {
	.tablet-hide {
		display: none !important;
	}
}
.tablet-show {
	display: none !important;

  @include xid-mq-1023{
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
	.mobile-hide {
		display: none !important;
	}
}
.mobile-show {
	display: none !important;

  @include xid-mq-767{
    display: block !important;
  }
}