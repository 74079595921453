.footer {
	border-top: 1px solid rgba(var(--color-neutral-800-rgb), .8);

  &__foot{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    padding: 32px 0;
    font-size: 12px;
    line-height: 1.66667;

    @include xid-mq-767{
      display: block;
      padding: 24px 0 32px;
    }
  }

  &__copyright{
    color: var(--color-neutral-500);
    text-align: center;
    display: block;
  }
}

// .footer__row {
// 	display: -webkit-box;
// 	display: -ms-flexbox;
// 	display: flex;
// 	padding: 80px 0 48px;
// 	border-bottom: 1px solid #E6E8EC;
// }
// @media only screen and (max-width: 1023px) {
// 	.footer__row {
// 		display: block;
// 	}
// }
// @media only screen and (max-width: 767px) {
// 	.footer__row {
// 		padding: 64px 0 48px;
// 	}
// }
// body.dark-mode .outer .footer__row {
// 	border-color: #353945;
// }
// .footer__col:first-child {
// 	-webkit-box-flex: 1;
// 	-ms-flex-positive: 1;
// 	flex-grow: 1;
// 	padding-right: 32px;
// }
// @media only screen and (max-width: 1023px) {
// 	.footer__col:first-child {
// 		position: relative;
// 		margin-bottom: 32px;
// 		padding: 0;
// 	}
// }
// .footer__col:nth-child(2) {
// 	display: -webkit-box;
// 	display: -ms-flexbox;
// 	display: flex;
// 	-ms-flex-negative: 0;
// 	flex-shrink: 0;
// 	width: 352px;
// }
// @media only screen and (max-width: 1179px) {
// 	.footer__col:nth-child(2) {
// 		width: 276px;
// 	}
// }
// @media only screen and (max-width: 1023px) {
// 	.footer__col:nth-child(2) {
// 		width: auto;
// 		margin-bottom: 32px;
// 	}
// }
// @media only screen and (max-width: 767px) {
// 	.footer__col:nth-child(2) {
// 		display: block;
// 		border-width: 1px 0;
// 		border-style: solid;
// 		border-color: #E6E8EC;
// 	}
// 	body.dark-mode .outer .footer__col:nth-child(2) {
// 		border-color: #353945;
// 	}
// }
// .footer__col:nth-child(3) {
// 	-ms-flex-negative: 0;
// 	flex-shrink: 0;
// 	width: 384px;
// 	padding-left: 32px;
// }
// @media only screen and (max-width: 1179px) {
// 	.footer__col:nth-child(3) {
// 		width: 304px;
// 	}
// }
// @media only screen and (max-width: 1023px) {
// 	.footer__col:nth-child(3) {
// 		width: 100%;
// 		padding: 0;
// 	}
// }
// .footer__logo {
// 	display: inline-block;
// 	margin-bottom: 32px;
// }
// .footer__logo img {
// 	width: 128px;
// }
// .footer__info {
// 	max-width: 256px;
// 	font-size: 24px;
// 	line-height: 1.33333;
// 	letter-spacing: -.01em;
// }
// @media only screen and (max-width: 1179px) {
// 	.footer__info {
// 		font-size: 16px;
// 	}
// }
// .footer__theme {
// 	display: -webkit-box;
// 	display: -ms-flexbox;
// 	display: flex;
// 	-webkit-box-align: center;
// 	-ms-flex-align: center;
// 	align-items: center;
// 	margin-top: 24px;
// }
// @media only screen and (max-width: 1023px) {
// 	.footer__theme {
// 		position: absolute;
// 		top: 5px;
// 		right: 0;
// 		margin: 0;
// 	}
// }
// .footer__details {
// 	margin-right: 16px;
// 	font-size: 12px;
// 	line-height: 1.66667;
// }
// @media only screen and (max-width: 767px) {
// 	.footer__details {
// 		margin-right: 10px;
// 	}
// }
// .footer__group {
// 	-webkit-box-flex: 0;
// 	-ms-flex: 0 0 50%;
// 	flex: 0 0 50%;
// }
// .footer__group:first-child {
// 	padding-right: 16px;
// }
// @media only screen and (max-width: 767px) {
// 	.footer__group:first-child {
// 		padding-right: 0;
// 	}
// }
// .footer__group:nth-child(2) {
// 	padding-left: 16px;
// }
// @media only screen and (max-width: 767px) {
// 	.footer__group:nth-child(2) {
// 		padding-left: 0;
// 	}
// }
// @media only screen and (max-width: 767px) {
// 	.footer__group:not(:last-child) {
// 		border-bottom: 1px solid #E6E8EC;
// 	}
// 	body.dark-mode .outer .footer__group:not(:last-child) {
// 		border-color: #353945;
// 	}
// }
// .footer__head {
// 	margin-bottom: 40px;
// 	font-size: 16px;
// 	line-height: 1.5;
// 	font-weight: 500;
// }
// @media only screen and (max-width: 1023px) {
// 	.footer__head {
// 		margin-bottom: 32px;
// 	}
// }
// @media only screen and (max-width: 767px) {
// 	.footer__head {
// 		position: relative;
// 		margin-bottom: 0;
// 		padding: 32px 32px 32px 0;
// 		font-size: 12px;
// 		line-height: 1;
// 		font-weight: 700;
// 		text-transform: uppercase;
// 	}
// }
// .footer__head .icon {
// 	display: none;
// }
// @media only screen and (max-width: 767px) {
// 	.footer__head .icon {
// 		display: inline-block;
// 		position: absolute;
// 		top: 50%;
// 		right: 10px;
// 		width: 10px;
// 		height: 10px;
// 		-webkit-transform: translateY(-50%);
// 		-ms-transform: translateY(-50%);
// 		transform: translateY(-50%);
// 		fill: #777E90;
// 		transition: -webkit-transform .2s;
// 		-webkit-transition: -webkit-transform .2s;
// 		-o-transition: transform .2s;
// 		transition: transform .2s;
// 		transition: transform .2s, -webkit-transform .2s;
// 	}
// }
// .footer__head.active .icon {
// 	-webkit-transform: translateY(-50%) rotate(180deg);
// 	-ms-transform: translateY(-50%) rotate(180deg);
// 	transform: translateY(-50%) rotate(180deg);
// }
// @media only screen and (min-width: 768px) {
// 	.footer__body {
// 		display: -webkit-box !important;
// 		display: -ms-flexbox !important;
// 		display: flex !important;
// 	}
// }
// @media only screen and (max-width: 767px) {
// 	.footer__body {
// 		display: none;
// 		padding-bottom: 32px;
// 	}
// }
// .footer__menu {
// 	display: -webkit-box;
// 	display: -ms-flexbox;
// 	display: flex;
// 	-webkit-box-orient: vertical;
// 	-webkit-box-direction: normal;
// 	-ms-flex-direction: column;
// 	flex-direction: column;
// 	-webkit-box-align: start;
// 	-ms-flex-align: start;
// 	align-items: flex-start;
// }
// .footer__link {
// 	font-family: 'DM Sans', sans-serif;
// 	font-size: 14px;
// 	line-height: 1.14286;
// 	font-weight: 700;
// 	color: #777E90;
// 	-webkit-transition: color .2s;
// 	-o-transition: color .2s;
// 	transition: color .2s;
// }
// .footer__link:hover {
// 	color: #3772FF;
// }
// .footer__link:not(:last-child) {
// 	margin-bottom: 24px;
// }
// .footer__category {
// 	margin-bottom: 40px;
// 	font-size: 16px;
// 	line-height: 1.5;
// 	font-weight: 500;
// }
// .footer__text {
// 	margin-bottom: 24px;
// }

@media only screen and (max-width: 767px) {
	.footer__note {
		display: none;
	}
}
.footer__note a {
	margin-left: 16px;
	font-weight: 600;
	color: #3772FF;
	-webkit-transition: color .2s;
	-o-transition: color .2s;
	transition: color .2s;
}
.footer__note a:hover {
	color: #044eff;
}