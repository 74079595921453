.header {
	position: relative;
	z-index: 20;
	padding: 12px 0 4px;
	border-bottom: 1px solid rgba(var(--color-neutral-800-rgb), .8);

  @include xid-mq-767{
		// padding: 48px 0 24px;
		border: none;
  }

  // Center
  &__center{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  // Logo
  &__logo{
    position: relative;
    z-index: 12;
    display: inline-block;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 32px;

    @include xid-mq-1179{
      margin-right: auto;
    }

    img{
			height: 75px;
      width: auto;
			object-fit: contain;
    }
  }

  // Wrapper
  &__wrapper{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;

    @include xid-mq-1179{
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      min-height: calc(var(--vh, 1vh) * 100);
      margin: 0;
      padding: 140px 24px 40px;
      -webkit-box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.2);
      box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.2);
      background: var(--color-black);
      visibility: hidden;
      opacity: 0;
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s;

      &.expanded{
        visibility: visible;
        opacity: 1;
      }
    }
  }

  // Burger
  &__burger{
    display: none;
    position: relative;
    width: 32px;
    height: 32px;
    background: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-left: 24px;

    @include xid-mq-1023{
      display: block; 
    }

    &:before,
    &:after{
      content: '';
      position: absolute;
      top: 16px;
      left: 6px;
      width: 20px;
      height: 2px;
      background: var(--color-neutral-500);
      border-radius: 2px;
      transition: -webkit-transform .2s;
      transition: transform .2s;
      transition: transform .2s, -webkit-transform .2s;
    }

    &:before{
      transform: translateY(-4px);
    }
    &:after{
      transform: translateY(3px);
    }

    &.expanded{
      &:before{
        -webkit-transform: translateY(0) rotate(-45deg);
        -ms-transform: translateY(0) rotate(-45deg);
        transform: translateY(0) rotate(-45deg);
      }
      &:after{
        -webkit-transform: translateY(0) rotate(45deg);
        -ms-transform: translateY(0) rotate(45deg);
        transform: translateY(0) rotate(45deg);
      }
    }
  }
}

// .header__upload {
// 	margin-right: 12px;
// }
// @media only screen and (max-width: 767px) {
// 	.header__upload {
// 		display: none;
// 	}
// }

// @media only screen and (max-width: 1179px) {
// 	.header__wrapper.visible {
// 		visibility: visible;
// 		opacity: 1;
// 	}
// }
// .pagewrap .header-wrapper__custom .footer__head.header-wrapper__custom{
//   background-color: var(--color-bg-black);
// }
// .header__wrapper .header__button {
// 	display: none;
// }
// @media only screen and (max-width: 767px) {
// 	.header__wrapper .header__button {
// 		display: -webkit-inline-box;
// 		display: -ms-inline-flexbox;
// 		display: inline-flex;
// 		margin-top: 16px;
// 	}
// }
// @media only screen and (min-width: 768px) {
// 	.header__wrapper .header__connect {
// 		display: none !important;
// 	}
// }
// .header__nav {
// 	display: -webkit-box;
// 	display: -ms-flexbox;
// 	display: flex;
// 	margin-right: auto;
// 	border-left: 1px solid #E6E8EC;
// }
// @media only screen and (max-width: 1179px) {
// 	.header__nav {
// 		display: -webkit-box;
// 		display: -ms-flexbox;
// 		display: flex;
// 		-webkit-box-orient: vertical;
// 		-webkit-box-direction: normal;
// 		-ms-flex-direction: column;
// 		flex-direction: column;
// 		margin: 0 0 40px;
// 		border: none;
// 	}
// }
// body.dark-mode .outer .header__nav {
// 	border-color: #353945;
// }
// .header__nav .header__link {
// 	margin-left: 32px;
// 	font-family: 'DM Sans', sans-serif;
// 	line-height: 40px;
// 	color: #777E90;
// 	-webkit-transition: color .2s;
// 	-o-transition: color .2s;
// 	transition: color .2s;
// }
// @media only screen and (max-width: 1179px) {
// 	.header__nav .header__link {
// 		margin-left: 0;
// 		font-size: 24px;
// 		line-height: 64px;
// 	}
// }
// .header__nav .header__link:hover, .header__nav .header__link.active {
// 	color: #3772FF;
// }
// .header__nav .header__link:nth-child(n+3) {
// 	display: none;
// }
// @media only screen and (max-width: 1179px) {
// 	.header__nav .header__link:nth-child(n+3) {
// 		display: block;
// 	}
// }
// .header__search {
// 	position: relative;
// 	-ms-flex-negative: 0;
// 	flex-shrink: 0;
// 	width: 256px;
// 	margin-right: 24px;
// }
// @media only screen and (max-width: 1023px) {
// 	.header__search {
// 		display: none;
// 	}
// }
// @media only screen and (max-width: 1179px) {
// 	.header__search {
// 		display: block;
// 		width: 100%;
// 		margin: auto 0 0;
// 	}
// }
// .header__input {
// 	width: 100%;
// 	height: 40px;
// 	padding: 0 42px 0 16px;
// 	background: none;
// 	border: 2px solid #E6E8EC;
// 	border-radius: 8px;
// 	font-family: 'Poppins', sans-serif;
// 	font-size: 12px;
// 	line-height: 1.66667;
// 	-webkit-transition: border-color .2s;
// 	-o-transition: border-color .2s;
// 	transition: border-color .2s;
// }
// .header__input::-webkit-input-placeholder {
// 	color: #777E90;
// }
// .header__input::-moz-placeholder {
// 	color: #777E90;
// }
// .header__input:-ms-input-placeholder {
// 	color: #777E90;
// }
// .header__input::-ms-input-placeholder {
// 	color: #777E90;
// }
// .header__input::placeholder {
// 	color: #777E90;
// }
// body.dark-mode .outer .header__input {
// 	border-color: #353945;
// 	color: #FCFCFD;
// }
// .header__input:focus {
// 	border-color: #777E90;
// }
// body.dark-mode .outer .header__input:focus {
// 	border-color: #777E90;
// }
// .header__result {
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	width: 42px;
// }
// .header__result .icon {
// 	width: 20px;
// 	height: 20px;
// 	fill: #777E90;
// 	-webkit-transition: fill .2s;
// 	-o-transition: fill .2s;
// 	transition: fill .2s;
// }
// .header__result:hover .icon {
// 	fill: #3772FF;
// }
// .header__item {
// 	position: relative;
// }
// @media only screen and (max-width: 767px) {
// 	.header__item {
// 		position: static;
// 	}
// }
// .header__item_notification {
// 	display: none;
// 	margin-right: 24px;
// }
// @media only screen and (max-width: 767px) {
// 	.header__item_notification {
// 		margin-right: 20px;
// 	}
// }
// .header__item_user {
// 	display: none;
// }
// @media only screen and (max-width: 767px) {
// 	.header__item_user {
// 		margin-right: 20px;
// 	}
// }
// .header__item_notification .header__head {
// 	position: relative;
// 	width: 40px;
// 	height: 40px;
// }
// .header__item_notification .header__head .icon {
// 	width: 24px;
// 	height: 24px;
// 	fill: #777E90;
// 	-webkit-transition: fill .2s;
// 	-o-transition: fill .2s;
// 	transition: fill .2s;
// }
// .header__item_notification .header__head:hover .icon {
// 	fill: #3772FF;
// }
// .header__item_notification .header__head.active:before {
// 	content: "";
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// 	width: 12px;
// 	height: 12px;
// 	border-radius: 50%;
// 	background: #45B26B;
// }
// .header__item_user .header__head {
// 	position: relative;
// 	display: -webkit-box;
// 	display: -ms-flexbox;
// 	display: flex;
// 	-webkit-box-align: center;
// 	-ms-flex-align: center;
// 	align-items: center;
// 	padding: 4px 16px 4px 4px;
// 	border-radius: 20px;
// 	-webkit-box-shadow: inset 0 0 0 2px #E6E8EC;
// 	box-shadow: inset 0 0 0 2px #E6E8EC;
// 	font-family: 'DM Sans', sans-serif;
// 	font-size: 14px;
// 	line-height: 1.14286;
// 	font-weight: 700;
// 	cursor: pointer;
// 	-webkit-transition: -webkit-box-shadow .2s;
// 	transition: -webkit-box-shadow .2s;
// 	-o-transition: box-shadow .2s;
// 	transition: box-shadow .2s;
// 	transition: box-shadow .2s, -webkit-box-shadow .2s;
// }
// @media only screen and (max-width: 767px) {
// 	.header__item_user .header__head {
// 		padding: 0;
// 		border-radius: 0;
// 		-webkit-box-shadow: none;
// 		box-shadow: none;
// 	}
// }
// body.dark-mode .outer .header__item_user .header__head {
// 	-webkit-box-shadow: inset 0 0 0 2px #353945;
// 	box-shadow: inset 0 0 0 2px #353945;
// }
// @media only screen and (max-width: 767px) {
// 	body.dark-mode .outer .header__item_user .header__head {
// 		-webkit-box-shadow: none;
// 		box-shadow: none;
// 	}
// }
// .header__item_user .header__head:hover {
// 	-webkit-box-shadow: inset 0 0 0 2px #3772FF;
// 	box-shadow: inset 0 0 0 2px #3772FF;
// }
// @media only screen and (max-width: 767px) {
// 	.header__item_user .header__head:hover {
// 		-webkit-box-shadow: none;
// 		box-shadow: none;
// 	}
// }
// body.dark-mode .outer .header__item_user .header__head:hover {
// 	-webkit-box-shadow: inset 0 0 0 2px #3772FF;
// 	box-shadow: inset 0 0 0 2px #3772FF;
// }
// @media only screen and (max-width: 767px) {
// 	body.dark-mode .outer .header__item_user .header__head:hover {
// 		-webkit-box-shadow: none;
// 		box-shadow: none;
// 	}
// }
// .header__avatar {
// 	-ms-flex-negative: 0;
// 	flex-shrink: 0;
// 	width: 32px;
// 	height: 32px;
// }
// .header__avatar img {
// 	width: 100%;
// 	height: 100%;
// 	border-radius: 50%;
// }
// .header__wallet {
// 	margin-left: 12px;
// }
// @media only screen and (max-width: 767px) {
// 	.header__wallet {
// 		display: none;
// 	}
// }
// body.dark-mode .outer .header__wallet {
// 	color: #FCFCFD;
// }
// .header__currency {
// 	color: #45B26B;
// }
// .header__body {
// 	position: absolute;
// 	left: 50%;
// 	-webkit-box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4);
// 	box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4);
// 	background: #FCFCFD;
// 	opacity: 0;
// 	visibility: hidden;
// 	-webkit-transform: scale(0.9);
// 	-ms-transform: scale(0.9);
// 	transform: scale(0.9);
// 	-webkit-transform-origin: 50% 50px;
// 	-ms-transform-origin: 50% 50px;
// 	transform-origin: 50% 50px;
// 	will-change: transform;
// 	-webkit-transition: all .4s;
// 	-o-transition: all .4s;
// 	transition: all .4s;
// }
// @media only screen and (max-width: 767px) {
// 	.header__body {
// 		left: 16px;
// 		right: 16px;
// 		-webkit-box-shadow: 0px 16px 32px 0 rgba(31, 47, 70, 0.4);
// 		box-shadow: 0px 16px 32px 0 rgba(31, 47, 70, 0.4);
// 	}
// }
// .header__body:before {
// 	content: "";
// 	position: absolute;
// 	left: 50%;
// 	bottom: 100%;
// 	width: 32px;
// 	height: 13px;
// 	-webkit-transform: translateX(-50%);
// 	-ms-transform: translateX(-50%);
// 	transform: translateX(-50%);
// 	background: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A") no-repeat 100% 50%/100% auto;
// }
// @media only screen and (max-width: 767px) {
// 	.header__body:before {
// 		display: none;
// 	}
// }
// body.dark-mode .outer .header__body {
// 	-webkit-box-shadow: 0px 16px 32px 0 rgba(35, 38, 47, 0.3);
// 	box-shadow: 0px 16px 32px 0 rgba(35, 38, 47, 0.3);
// }
// .header__item_notification .header__body {
// 	top: calc(100% + 44px);
// 	width: 396px;
// 	margin-left: -198px;
// 	padding: 40px 32px 32px;
// 	border-radius: 24px;
// }
// @media only screen and (max-width: 767px) {
// 	.header__item_notification .header__body {
// 		top: calc(100% + 16px);
// 		width: auto;
// 		margin: 0;
// 		padding: 32px 16px;
// 	}
// }
// body.dark-mode .outer .header__item_notification .header__body {
// 	background: #141416;
// }
// body.dark-mode .outer .header__item_notification .header__body:before {
// 	background-image: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%23141416'/%3E%3C/svg%3E%0A");
// }
// .header__item_user .header__body {
// 	top: calc(100% + 20px);
// 	width: 256px;
// 	margin-left: -128px;
// 	padding: 32px 16px 20px;
// 	border-radius: 12px;
// }
// @media only screen and (max-width: 1023px) {
// 	.header__item_user .header__body {
// 		left: auto;
// 		right: 0;
// 	}
// }
// @media only screen and (max-width: 767px) {
// 	.header__item_user .header__body {
// 		left: 16px;
// 		right: 16px;
// 		top: calc(100% + 16px);
// 		width: auto;
// 		margin: 0;
// 	}
// }
// body.dark-mode .outer .header__item_user .header__body {
// 	background: #23262F;
// }
// body.dark-mode .outer .header__item_user .header__body:before {
// 	background-image: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%2323262F'/%3E%3C/svg%3E%0A");
// }
// .header__item.active .header__body {
// 	opacity: 1;
// 	visibility: visible;
// 	-webkit-transform: scale(1);
// 	-ms-transform: scale(1);
// 	transform: scale(1);
// }
// .header__title {
// 	margin-bottom: 16px;
// }
// @media only screen and (max-width: 767px) {
// 	.header__title {
// 		margin: 0 16px;
// 	}
// }
// .header__list {
// 	margin: 0 -16px;
// }
// @media only screen and (max-width: 767px) {
// 	.header__list {
// 		margin: 0;
// 	}
// }
// .header__notification {
// 	position: relative;
// 	display: -webkit-box;
// 	display: -ms-flexbox;
// 	display: flex;
// 	-webkit-box-align: center;
// 	-ms-flex-align: center;
// 	align-items: center;
// 	padding: 16px 40px 16px 16px;
// 	border-radius: 16px;
// 	-webkit-transition: background .2s;
// 	-o-transition: background .2s;
// 	transition: background .2s;
// }
// .header__notification:hover {
// 	background: #F4F5F6;
// }
// body.dark-mode .outer .header__notification:hover {
// 	background: #23262F;
// }
// .header__preview {
// 	-ms-flex-negative: 0;
// 	flex-shrink: 0;
// 	width: 64px;
// 	height: 64px;
// 	margin-right: 24px;
// }
// @media only screen and (max-width: 767px) {
// 	.header__preview {
// 		margin-right: 16px;
// 	}
// }
// .header__preview img {
// 	width: 100%;
// 	height: 100%;
// 	-o-object-fit: cover;
// 	object-fit: cover;
// 	border-radius: 16px;
// }
// .header__details {
// 	-webkit-box-flex: 1;
// 	-ms-flex-positive: 1;
// 	flex-grow: 1;
// }
// .header__subtitle {
// 	font-size: 16px;
// 	line-height: 1.5;
// 	font-weight: 500;
// 	color: #141416;
// }
// body.dark-mode .outer .header__subtitle {
// 	color: #FCFCFD;
// }
// .header__price {
// 	font-weight: 500;
// 	color: #353945;
// }
// body.dark-mode .outer .header__price {
// 	color: #E6E8EC;
// }
// .header__date {
// 	font-size: 12px;
// 	line-height: 1.66667;
// 	color: #777E90;
// }
// .header__status {
// 	position: absolute;
// 	top: 50%;
// 	right: 16px;
// 	-webkit-transform: translateY(-50%);
// 	-ms-transform: translateY(-50%);
// 	transform: translateY(-50%);
// 	width: 12px;
// 	height: 12px;
// 	border-radius: 50%;
// 	background: #3772FF;
// }
// .header__item_notification .header__button {
// 	position: absolute;
// 	top: 40px;
// 	right: 32px;
// }
// @media only screen and (max-width: 767px) {
// 	.header__item_notification .header__button {
// 		position: static;
// 		width: 100%;
// 		margin-top: 20px;
// 	}
// }
// .header__name {
// 	font-size: 24px;
// 	line-height: 1.33333;
// 	font-weight: 600;
// 	letter-spacing: -.01em;
// }
// .header__code {
// 	display: -webkit-box;
// 	display: -ms-flexbox;
// 	display: flex;
// 	-webkit-box-align: center;
// 	-ms-flex-align: center;
// 	align-items: center;
// 	margin-bottom: 8px;
// }
// .header__number {
// 	font-weight: 500;
// 	color: #777E90;
// }
// .header__copy {
// 	margin-left: 8px;
// }
// .header__copy .icon {
// 	width: 16px;
// 	height: 16px;
// 	fill: #3772FF;
// 	-webkit-transition: fill .2s;
// 	-o-transition: fill .2s;
// 	transition: fill .2s;
// }
// .header__copy:hover .icon {
// 	fill: #044eff;
// }
// .header__wrap {
// 	margin-bottom: 7px;
// 	padding: 8px;
// 	border-radius: 16px;
// 	-webkit-box-shadow: 0px 24px 24px -8px rgba(15, 15, 15, 0.2);
// 	box-shadow: 0px 24px 24px -8px rgba(15, 15, 15, 0.2);
// }
// .header__line {
// 	display: -webkit-box;
// 	display: -ms-flexbox;
// 	display: flex;
// 	-webkit-box-align: center;
// 	-ms-flex-align: center;
// 	align-items: center;
// 	margin-bottom: 10px;
// }
// .header__img {
// 	-ms-flex-negative: 0;
// 	flex-shrink: 0;
// 	width: 40px;
// 	height: 40px;
// 	margin-right: 16px;
// }
// .header__img img {
// 	width: 100%;
// 	height: 100%;
// 	border-radius: 50%;
// }
// .header__info {
// 	font-size: 12px;
// 	line-height: 1.66667;
// 	color: #777E90;
// }
// .header__money {
// 	font-size: 24px;
// 	line-height: 1.33333;
// 	font-weight: 600;
// 	letter-spacing: -.01em;
// }
// .header__item_user .header__button {
// 	width: 100%;
// 	height: 32px;
// 	padding: 0 10px;
// 	font-size: 14px;
// }
// .header__menu .header__link {
// 	display: -webkit-box;
// 	display: -ms-flexbox;
// 	display: flex;
// 	-webkit-box-align: center;
// 	-ms-flex-align: center;
// 	align-items: center;
// 	padding: 17px 0 12px;
// 	font-family: 'DM Sans', sans-serif;
// 	font-size: 14px;
// 	line-height: 1.14286;
// 	font-weight: 700;
// 	color: #777E90;
// }
// .header__menu .header__link:not(:last-child) {
// 	border-bottom: 1px solid #E6E8EC;
// }
// body.dark-mode .outer .header__menu .header__link:not(:last-child) {
// 	border-color: #353945;
// }
// .header__menu .header__link[href]:hover {
// 	color: #3772FF;
// }
// .header__menu .header__link[href]:hover .icon {
// 	fill: #3772FF;
// }
// .header__icon {
// 	-ms-flex-negative: 0;
// 	flex-shrink: 0;
// 	width: 20px;
// 	margin-right: 8px;
// }
// .header__icon .icon {
// 	width: 20px;
// 	height: 20px;
// 	fill: #777E90;
// 	-webkit-transition: fill .2s;
// 	-o-transition: fill .2s;
// 	transition: fill .2s;
// }
// .header .theme {
// 	margin-left: auto;
// }
// .header__burger {
// 	display: none;
// }
// @media only screen and (max-width: 767px) {
// 	.header__burger {
// 		display: block;
// 		position: relative;
// 		width: 32px;
// 		height: 32px;
// 		background: none;
// 		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// 	}

// 	.header__burger:before, .header__burger:after {
// 		content: '';
// 		position: absolute;
// 		top: 16px;
// 		left: 6px;
// 		width: 20px;
// 		height: 2px;
// 		background: #777E90;
// 		border-radius: 2px;
// 		transition: -webkit-transform .2s;
// 		-webkit-transition: -webkit-transform .2s;
// 		-o-transition: transform .2s;
// 		transition: transform .2s;
// 		transition: transform .2s, -webkit-transform .2s;
// 	}
// 	.header__burger:before {
// 		-webkit-transform: translateY(-4px);
// 		-ms-transform: translateY(-4px);
// 		transform: translateY(-4px);
// 	}
// 	.header__burger:after {
// 		-webkit-transform: translateY(3px);
// 		-ms-transform: translateY(3px);
// 		transform: translateY(3px);
// 	}
// }
// @media only screen and (max-width: 767px) {
// 	.header__center>.header__connect {
// 		display: none !important;
// 	}
// }
// .header.registered .header__connect {
// 	display: none;
// }
// .header.registered .header__item_notification, .header.registered .header__item_user {
// 	display: block;
// }