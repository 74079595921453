@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&family=Poppins:wght@400;500;600;700&display=swap");

@import 'variables';
@import 'media-queries';
@import 'mixins';

// plugins
@import 'lib/ui8-style';

// @import './commons';
// utilities
.center__full{
  max-width: 100%;
}

.center__admin{
  max-width: 1778px;
}

.w-full{
  width: 100%;
}

.border-none{
  border: none !important;
}

@import './utilities/typography';
@import './utilities/border';
@import './utilities/padding';
@import './utilities/margin';
@import './utilities/visibility';
@import './utilities/color';

.button{
  &:disabled,
  &.disabled{
    background-color: $color-accent-gray-dark;
  }
}

.justify__center{
  justify-content: center;
}
.space__between{
  justify-content: space-between;
}
.flex__row{
  flex-flow: row wrap;
}

// blocks
%fieldResetHide{
  display: none;
}

%fieldResetSpace{
  width: 100%;
  margin: 0;
}

%fieldInputWrapperHasIcon{
  padding: 0 48px 0 16px !important;
  height: 44px;
  line-height: 44px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  font-family: $font_poppins !important;

  @include dark(){
    color: $color-accent-white;
  }
  &:focus{
    background-color: transparent !important;
  }
}

%fieldInputStyling{
  border: 2px solid $color-accent-gray;
  border-radius: 12px;
  transition: border .2s;
  @include dark{
    border-color: $color-accent-dark;
  }

  &.Mui-focused{
    border-color: $color-accent-dark-light;
  }
}

%fieldIconWrapper{
  position: absolute;
  top: 50%;
  right: 8px;
  width: 32px;
  height: 32px;
  transform: translateY(-50%);
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px $color-accent-gray;
  transition: transform .2s;

  @include dark{
    box-shadow: inset 0 0 0 2px $color-accent-dark-light;
  }
}

%fieldIconSvg{
  width: 16px;
  height: 16px;
  fill: $color-accent-dark-light;

  @include dark{
    fill: $color-accent-white;
  }
}

%fieldAdornmentTypography{
  font-family: $font_poppins;
  font-size: 14px;
  font-weight: 600 !important;
  margin: 0 !important;
  color: $color-accent-dark-second;

  @include dark{
    color: $color-accent-white;
  }
}

@import './blocks/fieldset';
// @import './blocks/fields';
@import './blocks/checkbox';
@import './blocks/radio';
@import './blocks/link';
@import './blocks/select';
@import './blocks/datepicker';
@import './blocks/textarea';
@import './blocks/paper';
@import './blocks/tables';
@import './blocks/video';
@import './blocks/status';
@import './blocks/note';
@import './blocks/modal';
@import './blocks/step_process';
@import './blocks/upload';
@import './blocks/tooltip';
@import './blocks/change-log';
@import './blocks/charts';

// components
@import './components/header';
@import './components/footer';
@import './components/navigation';
@import './components/breadcrumbs';
@import './components/control';
@import './components/main_item';
@import './components/partners';
@import './components/intro';
// @import './components/investor';
@import './components/blog_item';
@import './components/description';
@import './components/pricings';
@import './components/explore';
@import './components/deals';
@import './components/faq';
@import './components/signinup';
@import './components/singlepage';
@import './components/tenant';
@import './components/new-tenant';
@import './components/project';
@import './components/kycinfo';
@import './components/tenantdetails';
@import './components/not-found';
@import './components/walletconnect';
@import './components/dataroom';
@import './components/country';
@import './components/levels';
@import './components/admin_setting';
@import './components/onboarding-best';
@import './components/usecases';
@import './components/techstack';
@import './components/profile';
@import './components/user';
@import './components/learn';
@import './components/success';
@import './components/opportunity';
@import './components/sharelove';

.toasts-container{
  ~ iframe{
    display: none !important;
  }
}

.display-none{
  display: none !important;
}

.variants__list.investhub-learn__list {
  flex-wrap: wrap;

  .investhub-learn__item {
    margin-bottom: 32px;
  }
}