[class^="button"] {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 48px;
	padding: 0 24px;

  background: var(--color-primary);
  font-size: 15px;
  line-height: 1.6;
  color: var(--color-white);
  border-radius: 6px;
	-webkit-transition: all .2s;
	-o-transition: all .2s;
	transition: all .2s;
  font-weight: 700;
  text-align: center;

  &:hover{
    background: #f32523;
  }

  &.disabled{
    opacity: .5;
    pointer-events: none;
  }
}

.button{
  // Variation button
  &-stroke{
    background: none;
    -webkit-box-shadow: 0 0 0 2px var(--color-neutral-500) inset;
    box-shadow: 0 0 0 2px var(--color-neutral-500) inset;
    color: var(--color-neutral-500);

    &:hover{
      background: var(--color-primary);
      -webkit-box-shadow: 0 0 0 2px var(--color-primary) inset;
      box-shadow: 0 0 0 2px var(--color-primary) inset;
      color: var(--color-white);
    }
  }
  &-transparent{
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  // Variation Size
  &-small{
    height: 40px;
    padding: 0 16px;
    font-size: 14px;
  }

  &-icon{
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    border-radius: 50%;
    .icon{
      fill: var(--color-neutral-500);
      width: 16px;
      height: 16px;
      margin: 0 !important;
    }
  }


  &-group{
    padding: 0;
    background: transparent !important;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
  }
}