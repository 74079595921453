// Overwrite material UI to match with XID app design-system
.xid-field{
  &.MuiFormControl-root{
    margin: 0;
    width: 100%;
    // Label
    .MuiFormLabel-root{
      margin-bottom: 6px;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.71429;
      color: var(--color-neutral-500);

      transform: scale(1);
      position: relative;
      text-align: left;

      // Error
      > span{
        color: var(--color-alert-error);
      }

      + .MuiInput-formControl{
        margin-top: 0;
      }
    }

    // Input Wrap
    .MuiInputBase-root{
      width: 100%;
      height: 48px;
      padding: 0 10px;
      border-radius: 6px;
      border: 2px solid transparent;
      background: var(--color-neutral-800);
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
      font-weight: 600;
      line-height: 1.6;
      color: var(--color-white);
      transition: all .2s;
      &:before,
      &:after{
        display: none !important;
      }

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder,
      &::placeholder {
        color: var(--color-neutral-500);
      }

      // focus
      &.Mui-focused{
        border-color: var(--color-neutral-500);
        background: var(--color-neutral-800);

        svg.icon{
          fill: var(--color-white);
        }
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="password"]{
      width: 100%;
      padding: 12px 18px;
      height: initial;
      padding-right: 0;
      font-weight: 600;
      font-family: var(--font-body);
    }

    &.has-icon{
      svg.icon{
        fill: var(--color-neutral-500);
        width: 32px;
      }
      input[type="text"],
      input[type="email"],
      input[type="password"]{
        padding-left: 8px;
      }
    }

    &.has-adornment-end{
      input[type="text"],
      input[type="email"],
      input[type="password"]{
        padding-left: 8px;
      }

      .MuiInputAdornment-root{
        > .button-transparent{
          font-weight: 600;
          color: var(--color-white);
        }

        &.MuiInputAdornment-positionEnd{
          > .button-transparent{
            padding-left: 0;
          }
        }
      }
    
    }

    // disabled state
    .MuiInputBase-root.Mui-disabled{
      border-color: var(--color-neutral-500);
      background: var(--color-neutral-800);
      cursor: not-allowed;

      svg.icon{
        fill: var(--color-white);
      }

      input[type="text"],
      input[type="email"],
      input[type="password"]{
        cursor: not-allowed;
      }
    }
  }

}