[class^="status"] {
	display: inline-block;
	padding: 0 8px;
	border-radius: 4px;
	font-size: 11px;
	line-height: 26px;
	font-weight: 700;
	text-transform: uppercase;
	color: var(--color-white);
}

$status_styles: (
  'primary': var(--color-primary),
  'default': var(--color-neutral-500),
  'error': var(--color-alert-error),
  'success': var(--color-alert-success)
);

@each $state, $property in $status_styles {
  .status-#{$state}{
    background: $property;
  }
  .status-stroke-#{$state}{
    color: $property;
    box-shadow: inset 0 0 0 2px $property; 
  }
}