

/*  
 * Breadcrumbs
 **/
.breadcrumbs {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
@media only screen and (max-width: 767px) {
	.breadcrumbs {
		display: none;
	}
}
.breadcrumbs__item {
	font-size: 14px;
	line-height: 1.14286;
	font-weight: 700;
}
.breadcrumbs__item:not(:last-child) {
	margin-right: 33px;
}
.breadcrumbs__item:not(:last-child):after {
	content: "";
	display: inline-block;
	width: 6px;
	height: 9px;
	margin-left: 33px;
	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto;
}
.breadcrumbs__link {
	color: var(--color-neutral-500);
	-webkit-transition: color .2s;
	-o-transition: color .2s;
	transition: color .2s;
}
.breadcrumbs__link:hover {
	color: var(--color-primary);
}