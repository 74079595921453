.connect-wallet{
  &__list{
    flex-flow: row wrap;
    margin-bottom: -32px;
  }

  &__item{
    flex-basis: calc(33.333% - 32px);
    width: calc(33.333% - 32px);

    padding: 24px;
    margin-bottom: 32px;

    @include mq_1023{
      flex-basis: calc(50% - 32px);
      width: calc(50% - 32px);
    }

    @include mq_767{
      width: 100%;
      display: block;
    }
  }

  &__img{
    margin-bottom: 24px;
    img{
      height: 50px;
      width: 50px;
    }
  }

  &__title{
    color: $color-accent-dark-second;

    @include dark(){
      color: $color-accent-gray;
    }
  }
}