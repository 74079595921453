.details {
  &__center{
    max-width: 896px;
  }

  &__top{
    margin-bottom: 64px;

    @include xid-mq-767{
      margin-bottom: 32px;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(var(--color-neutral-800-rbg), .8);
    }
  }

  &__title{
    margin-bottom: 16px;
  }

  &__subtitle{
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
  }

  &__list{
    margin-bottom: 40px;

    @include xid-mq-1179{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 0 -20px 64px;
    }

    @include xid-mq-767{
      display: block;
      margin: 0 0 32px;
    }
  }

  &__item{
    @include xid-mq-1179{
      -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(100% - 40px);
      flex: 0 0 calc(100% - 40px);
      width: calc(100% - 40px);
      margin: 0 20px;
    }

    @include xid-mq-767{
      width: 100%;
      margin: 0;
    }

    &:not(:last-child){
      margin-bottom: 40px;

      @include xid-mq-1179{
        margin-bottom: 0;
      }
      @include xid-mq-767{
        margin-bottom: 32px;
      }
    }
  }

  &__info{
    color: var(--color-neutral-500);
    strong{
      font-weight: 500;
    }

    + .details__fieldset{
      margin-top: 56px;
    }
  }

  &__row{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: row wrap;
    margin: 0 -16px;

    // @include xid-mq-1179{
    //   display: block;
    //   margin: 0;
    // }
  }

  &__col{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 0 16px;

    &.is-full{
      flex: 0 0 calc(100% - 32px);
      width: calc(100% - 32px);
    }

    // @include xid-mq-1179{
    //   width: 100%;
    //   margin: 0;
    // }

    &:first-child{
      padding-bottom: 42px;
      margin-bottom: 42px;
      border-bottom: 1px solid rgba(var(--color-neutral-800-rgb), .8);

      @include xid-mq-1179{
        padding-bottom: 0px;
        margin-bottom: 34px;
      }
      @include xid-mq-767{
        margin-bottom: 42px;
      }
    }

    &:not(:last-child){
      @include xid-mq-1179{
        margin-bottom: 64px;
      }

      @include xid-mq-767{
        margin-bottom: 40px;
        // padding-bottom: 32px;
        // border-bottom: 1px solid rgba(var(--color-neutral-800-rgb), .8);
      }
    }
  }

  &__fieldset{
    >.xid-field:not(:last-child){
      margin-bottom: 32px;
    }
  }
}