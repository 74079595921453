/*  
 * Control
 **/
 .control {
	padding: 24px 0;
	border-bottom: 1px solid rgba(var(--color-neutral-800-rgb), 0.8);
}
@media only screen and (max-width: 1179px) {
	.control {
		padding: 16px 0;
	}
}
@media only screen and (max-width: 767px) {
	.control {
		padding: 24px 0;
	}
}

.control__center {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.control__button {
	margin-right: auto;

  .icon{
    width: 10px;
    height: 10px;
    fill: var(--color-neutral-500);
  }

  &:hover{
    .icon{
      fill: var(--color-white);
    }
  }
}