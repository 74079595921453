.page__simple {
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  @include xid-mq-1339{
    padding: 0;
  }

  @include xid-mq-1259{
    padding-left: 0;
  }

  .entry{
    width: 100%;
  }
}