.entry {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 48px;
  /* background: var(--color-neutral-900); */
  @include xid-mq-767{
    padding: 48px 24px 48px;
  }

  // Wrapper
  &__wrapper{
    width: 100%;
    max-width: 300px;

    @include xid-mq-767{
      max-width: 100%;
    }
  }

  // Logo
  &__logo{
    display: inline-block;
    width: 145px;
    // margin-bottom: 32px;

    img{
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  // Title
  &__title{
    margin-bottom: 16px;
  }

  // Text
  &__text{
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71429;
    // color: white;
    // margin-top: 8px;
  }

  &__top{
    margin-bottom: 32px;
    // padding-bottom: 32px;
    border-bottom: 2px solid rgba(var(--color-neutral-800-rgb), .8);

    @include xid-mq-767{
      // padding-bottom: 24px;
      margin-bottom: 24px;
    }
  }

  &__fieldset .entry__button {
    width: 100%;
    margin-top: 8px;
  }

  .xid-field{
    margin-bottom: 12px;
    width: 100%;
    &.field__checkbox{
      margin-top: 4px;
    }
  }

  // Note
  &__note{
    margin-top: 32px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71429;
    color: var(--color-white);

    @include xid-mq-767{
      margin-top: 24px;
    }

    a{
      color: var(--color-white);
      &:hover{
        color: var(--color-primary);
      }
    }
  }

  // Info
  &__info{
    margin-top: 8px;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.23077;
    color: var(--color-neutral-500);

    a{
      font-weight: 700;
      color: var(--color-white);
      transition: color .2s;

      &:hover,
      &:focus{
        color: var(--color-primary);
      }
    }
  }
}




// ### Buttons
// .entry__btns {
//   display: flex;
//   margin: 0 -4px;
// }

// .entry__btns .entry__button {
//   flex: 0 0 calc(50% - 8px);
//   width: calc(50% - 8px);
//   margin: 0 4px;
//   padding: 0 16px;
// }

// .entry__btns .entry__button img {
//   margin-right: 10px;
// }