:root {
  // Primary Colors
  --color-white: #fff;
  --color-black: #000;
  --color-primary: #EE3B39;
  --color-neutral-500: #737373;
  --color-neutral-500-rgb: 115, 115, 115;
  --color-neutral-700: #2E2E2E;
  --color-neutral-700-rgb: 46, 46, 46;
  --color-neutral-800: #1a1a1a;
  --color-neutral-800-rgb: 26, 26, 26;
  --color-neutral-900: #0D0D0D;
  --color-neutral-900-rgb: 13, 13, 13;

  // Alert
  --color-alert-error: #ff6a55;
  --color-alert-success: #45B26B;
}