/* Commons
--------------------------------------------- */
@import './commons/commons';

/* Links
--------------------------------------------- */
@import './links/links';

/* Headings
--------------------------------------------- */
@import './headings/headings';

/* Media
--------------------------------------------- */
svg, img {
	vertical-align: middle;
}
img, iframe{
  max-width: 100%;
}
iframe{
  width: 100%;
  border: none;
}
img{
  height: auto;
}

/* Visibility
--------------------------------------------- */
@import './visibility/visibility';