.field-upload{
  // label
  &__component{
    > p strong{
      margin-bottom: 6px;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.71429;
      color: var(--color-neutral-500);
      transform: scale(1);
      position: relative;
      text-align: left;
      display: block;
    }
  }

  &__link{
    padding-bottom: 0;
  }

  // field upload button
  &__inner{
    position: relative;
  }
  &__dragzone{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 182px;
    border-radius: 6px;
    overflow: hidden;
    border: 2px solid transparent;
    background-color: var(--color-neutral-800);
    transition: all .2s;

    &:hover,
    &:focus{
      border-color: var(--color-neutral-500);
      background: var(--color-neutral-800);
    }
  }
  &__button{
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .icon{
      width: 24px;
      height: 24px;
      fill: var(--color-neutral-500);
    }

    &-contained{
      // background-color: $color-accent-dark;
    }
  }
  &__format{
    font-size: 12px;
    line-height: 1.66667;
    // color: $color-accent-dark-light;
    margin-top: 6px;
  }

  &__note{
    margin-top: 16px;
    font-size: 12px;
    a{
      // color: $color-accent-primary;
      font-weight: 700;

      &:hover,
      &:focus{
        // color: $color-accent-primary-dark;
      }
    }
  }
}

// Preview
.field-upload__preview{
  padding: 12px;
  border-radius: 6px;
  overflow: hiddden;
  border: 2px solid var(--color-neutral-500);
  background-color: var(--color-neutral-800);
  embed{
    width: 100%;
  }
}
.preview{
  &__inner{
    overflow: hidden;
    img{
      width: 100%;
    }
  }

  &__title{
    display: block;
    margin-top: 4px;
    color: var(--color-white);
    font-size: 14px;
    font-weight: 600;
  }
  &__clear{
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 24px;
    font-family: var(--text-body);
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    color: var(--color-alert-error);
    -webkit-transition: color .2s;
    -o-transition: color .2s;
    transition: color .2s;

    .icon{
      width: 24px;
      height: 24px;
      margin-right: 8px;
      fill: var(--color-alert-error);
      -webkit-transition: fill .2s;
      -o-transition: fill .2s;
      transition: fill .2s;
    }

    &:hover{
      color: var(--color-primary);
      .icon{
        fill: var(--color-primary);
      }
    }
  }
}