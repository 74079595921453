#root{
  display: flex;
  flex-flow: column nowrap;
  height: 100dvh;
  
  .page__simple,
  .outer{
    flex: 1;
  }
}

/* Simple
--------------------------------------------- */
@import './simple/simple';

/* Outer
--------------------------------------------- */
@import './outer/outer';