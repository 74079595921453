// card upload
.investhub-upload{
  &__row{
    display: flex;
    justify-content: flex-start;
    flex-row: row wrap;
    margin: 0 -16px;

    @include mq_767{
      display: block;
      margin: 0;
    }
  }

  &__card{
    flex: 0 0 calc(33.333% - 32px);
    width: calc(33.333% - 32px);

    @include mq_767{
      width: 100%;
    }

    &:not(:last-child){
      margin-bottom: 32px;
    }

    .preview__inner{
      width: 100%;
    }
  }

}